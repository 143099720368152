import {
  DecryptText,
  validarExternos,
  EncryptText,
  sendGetHttp,
  sendGetHttpClean,
  //sendGetHttpWithParameters,
  sendPostHttp,
  setLocalStorage,
  validateJson,
} from "../utils/helpers";

export function AuthLogin({ parameters }, remember) {
  return new Promise(async (resolve, reject) => {
    const API = "configuracion";
    const ROUTE = "login";

    try {
      const response = await sendPostHttp({
        parameters,
        api: API,
        route: ROUTE,
      });

      if (response.error) {
        return reject(response.message);
      }
      const externos = validarExternos(response.id_aplicacion.toString());
      //const nomb_empresa = !externos ? response.empresa : externos;
      // const responseCartografias = await sendGetHttpWithParameters({
      //   api: "riegov2/api_reportes",
      //   route: "cartografias",
      //   token_access: response.token,
      //   parameters: { nomb_empresa },
      // });

      const token_encrypted = EncryptText(response.token);
      const empresa_encrypted = EncryptText(response.empresa);
      const usuario_encrypted = EncryptText(response.usuario);
      const id_aplicacion_encrypted = EncryptText(
        response.id_aplicacion.toString()
      );
      const perfil_encrypted = EncryptText(
        response.dataUsuarioEmpresa.id_perfil.toString()
      );

      if (remember) {
        const ids = localStorage.getItem("ids_recordados_mosca")
          ? JSON.parse(localStorage.getItem("ids_recordados_mosca"))
          : [];
        const isCreated = ids.find((x) => x.id === response.id_aplicacion);
        if (!isCreated) {
          localStorage.setItem(
            "ids_recordados_mosca",
            JSON.stringify([
              ...ids,
              { id: response.id_aplicacion, empresa: response.empresa },
            ])
          );
        }
      }

      setLocalStorage([
        { name: "known_signed", value: token_encrypted },
        { name: "app", value: EncryptText("mosca_fruta") },
        { name: "id_aplicacion", value: id_aplicacion_encrypted },
        { name: "usuario", value: usuario_encrypted },
        { name: "empresa", value: empresa_encrypted },
        { name: "perfil", value: perfil_encrypted },
       // { name: "cartografias", value: responseCartografias.data },
      ]);
      setLocalStorage([
        {
          name: "campanas_a",
          value: response.InformacionCargaInicial.Campanas,
        },
        { 
          name: "lotes", 
          value: response.InformacionCargaInicial.Lotes 
        },
        { 
          name: "variedad", 
          value: response.InformacionCargaInicial.Variedad 
        },
        {
          name: "cultivos",
          value: response.InformacionCargaInicial.Cultivos,
        },
        {
          name: "niveles",
          value: response?.InformacionCargaInicial?.Niveles ?? [],
        },
        {
          name: "cartillas",
          value: response.InformacionCargaInicial.Cartillas,
        },
        { 
          name: "fundos", 
          value: response.InformacionCargaInicial.Fundos 
        },
        { 
          name: "evaluadores", 
          value: response.InformacionCargaInicial.Evaluadores 
        },
        { 
          name: "reportes", 
          value: response.InformacionCargaInicial.Reportes 
        },
        { 
          name: "umbrales", 
          value: response.InformacionCargaInicial.Umbrales 
        },
        { 
          name: "usuarios", 
          value: response.InformacionCargaInicial.Usuarios 
        },
        { 
          name: "ventanas", 
          value: response.InformacionCargaInicial.Ventanas 
        },
        {
          name: "Reportes",
          value: response.InformacionCargaInicial.Reportes
        }
        // {
        //   name: "cultivos_a",
        //   value: response.InformacionCargaInicial.Cultivos_a ?? [],
        // },
        // {
        //   name: "fundos_a",
        //   value: response.InformacionCargaInicial.Fundos_a ?? [],
        // },
        // {
        //   name: "tipo_trampas",
        //   value: response.InformacionCargaInicial.TiposTrampas ?? [],
        // },
        // { 
        //   name: "umbrales", 
        // value: response.InformacionCargaInicial.Umbrales ?? [],
        // },
        // {
        //   name: "trampas",
        //   value: response.InformacionCargaInicial.Trampas ?? [],
        // },
        // {
        //   name: "Carts",
        //   value: response?.InformacionCargaInicial?.Carts ?? [],
        // },

      ]);

      resolve(true);
    } catch (error) {
      const message_error_server_500 =
        error === "Failed to fetch"
          ? "Hay problemas con la conexión. Intentelo más tarde."
          : error;
      reject(message_error_server_500);
    }
  });
}

export function verifyJWTApi() {
  return new Promise(async (resolve, reject) => {
    const API = "configuracion";
    const ROUTE = "jwtVerifiyClients";
    const TokenLocalStorage = localStorage.getItem("known_signed");

    if (localStorage.length === 0) {
      return reject(false);
    }

    if (!TokenLocalStorage) {
      return reject(false);
    }

    const TOKEN_ACCESS = DecryptText(localStorage.getItem("known_signed"));

    try {
      const response = await sendGetHttp({
        api: API,
        route: ROUTE,
        token_access: TOKEN_ACCESS,
      });

      if (response.error) {
        return reject(response.message);
      }

      resolve("");
    } catch (error) {
      const ids = localStorage.getItem("ids_recordados_mosca")
        ? JSON.parse(localStorage.getItem("ids_recordados_mosca"))
        : [];
      localStorage.clear();
      localStorage.setItem("ids_recordados_mosca", JSON.stringify(ids));
      window.location.reload();
      const message_error_server_500 =
        error === "Failed to fetch"
          ? "Hay problemas con la conexión. Intentelo más tarde."
          : error;
      reject(message_error_server_500);
    }
  });
}

export function validateKeysLocalStorage() {
  if (!validateJson(localStorage.getItem("maquinas"))) {
    return false;
  }

  const storeInitial = [
    localStorage.getItem("empresa"),
    localStorage.getItem("id_aplicacion"),
    localStorage.getItem("known_signed"),
    localStorage.getItem("usuario"),
  ];

  let confirmation = true;

  for (let i = 0; i < storeInitial.length; i++) {
    if (!storeInitial[i]) {
      confirmation = null;
      break;
    }
  }

  if (!confirmation) {
    return false;
  } else {
    return true;
  }
}

export function validateInitialKeysLocalStorage() {
  const fundos = localStorage.getItem("fundos")
    ? JSON.parse(localStorage.getItem("fundos"))
    : null;
  const cultivos = localStorage.getItem("cultivos")
    ? JSON.parse(localStorage.getItem("cultivos"))
    : null;
  // const tipo_trampas = localStorage.getItem("tipo_trampas")
  //   ? JSON.parse(localStorage.getItem("tipo_trampas"))
  //   : null;
  const lotes = localStorage.getItem("lotes")
    ? JSON.parse(localStorage.getItem("lotes"))
    : null;
  // const cartografias = localStorage.getItem("cartografias")
  //   ? JSON.parse(localStorage.getItem("cartografias"))
  //   : null;
  const cartografias = [];
  const app = localStorage.getItem("app")
    ? DecryptText(localStorage.getItem("app"))
    : "";

  if (
    !fundos ||
    !cultivos ||
    //!tipo_trampas ||
    !lotes ||
    !cartografias ||
    app !== "mosca_fruta"
  ) {
    const ids = localStorage.getItem("ids_recordados_mosca")
      ? JSON.parse(localStorage.getItem("ids_recordados_mosca"))
      : [];
    localStorage.clear();
    localStorage.setItem("ids_recordados_mosca", JSON.stringify(ids));
  }
}

export function AuthLogout() {
  sendGetHttpClean();
}
